import React, { Component } from 'react';

import styles from './Home.module.scss';


class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      textSize: 3,
      cakeSize: 5,
      showit: false
    };

    this.maxText = 20;
    this.maxCake = 100;
  }


  makeLarger() {

    if(this.state.textSize < this.maxText) {
        this.setState({textSize: this.state.textSize + 5});
    }
    if(this.state.cakeSize <= this.maxCake) {
        this.setState({cakeSize: this.state.cakeSize + 30});
    } else {
        this.setState({showit: true});
    }
  }

  render() {
    return (
      <div className={styles.teaser}>
        <div className={styles.imageBackground} />
        <div className={styles.Content} onClick={() => this.makeLarger() }>
        <div className={styles.clickText} style={{fontSize: `${this.state.textSize}vw`}}>Eat Me</div>
        {!this.state.showit && <div className={styles.cake} style={{fontSize: `${this.state.cakeSize}vw`}}><span  role="img" aria-label="cake emoji">🎂</span></div>}
        {this.state.showit && <div className={styles.cake} style={{fontSize: '100vh'}}><span  role="img" aria-label="cake emoji">😱</span></div>}
        </div>
      </div>
    );
  }
}

export default Home;
