import React from 'react';
import { Home } from './Pages';
import './App.css';

function App() {
  console.log('please check back soon...');
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
